import { MainNavItem, SidebarNavItem } from "@/types";

interface DocsConfig {
  mainNav: MainNavItem[];
  sidebarNav: SidebarNavItem[];
}

export const docsConfig: DocsConfig = {
  mainNav: [
    // {
    //   title: "Documentation",
    //   href: "/templates",
    // },
    // {
    //   title: "Sections",
    //   href: "/sections",
    // },
    // {
    //   title: "Pricing",
    //   href: "/pricing",
    // },
    // {
    //   title: "Submit Feedback",
    //   href: "https://magicui.featurebase.app/",
    //   external: true,
    // },
    // {
    //   title: "Roadmap",
    //   href: "https://magicui.featurebase.app/roadmap",
    //   external: true,
    // },
    // {
    //   title: "Discord",
    //   href: "https://discord.gg/X4BBMBjHNf",
    //   external: true,
    // },
  ],
  sidebarNav: [
    // {
    //   title: "Account",
    //   items: [
    //     {
    //       title: "Login",
    //       href: "/login",
    //       items: [],
    //     },
    //     {
    //       title: "Register",
    //       href: "/register",
    //       items: [],
    //     },
    //   ],
    // },
    {
      title: "Getting Started",
      items: [
        {
          title: "Introduction",
          href: "/docs",
          items: [],
        },
        {
          title: "Installation",
          href: "/docs/installation",
          items: [],
        },
        // {
        //   title: "Changelog",
        //   href: "/docs/changelog",
        //   items: [],
        // },
        // {
        //   title: "Story",
        //   href: "/docs/story",
        //   items: [],
        // },
      ],
    },
    {
      title: "Templates",
      items: [
        {
          title: "Mobile",
          href: `/docs/templates/mobile`,
          items: [],
          label: "New",
          paid: true,
          event: "template_mobile_clicked",
        },
        {
          title: "SaaS",
          href: `/docs/templates/saas`,
          items: [],
          label: "",
          paid: true,
          event: "template_saas_clicked",
        },
        {
          title: "Startup",
          href: `/docs/templates/startup`,
          items: [],
          label: "",
          paid: true,
          event: "template_startup_clicked",
        },
        {
          title: "Portfolio",
          href: `/docs/templates/portfolio`,
          items: [],
          label: "",
          event: "template_portfolio_clicked",
        },
      ],
    },
    {
      title: "Page Sections",
      items: [
        {
          title: "Header",
          href: `/docs/sections/header`,
          items: [],
        },
        {
          title: "Hero",
          href: `/docs/sections/hero`,
          items: [],
          label: "",
        },
        {
          title: "Social Proof Press",
          href: `/docs/sections/social-proof-press`,
          items: [],
        },
        {
          title: "Social Proof Companies",
          href: `/docs/sections/social-proof-companies`,
          items: [],
        },
        {
          title: "Social Proof Testimonials",
          href: `/docs/sections/social-proof-testimonials`,
          items: [],
        },
        {
          title: "Stats",
          href: `/docs/sections/stats`,
          items: [],
          label: "",
        },
        {
          title: "Feature Scroll",
          href: `/docs/sections/feature-scroll`,
          items: [],
          label: "New",
        },
        {
          title: "Feature Slideshow",
          href: `/docs/sections/feature-slideshow`,
          items: [],
          label: "",
        },
        {
          title: "Feature Cards",
          href: `/docs/sections/feature-cards`,
          items: [],
          label: "",
        },
        {
          title: "Carousel",
          href: `/docs/sections/carousel`,
          items: [],
          label: "New",
        },
        {
          title: "Pricing",
          href: `/docs/sections/pricing`,
          items: [],
          label: "",
        },
        {
          title: "Call To Action",
          href: `/docs/sections/call-to-action`,
          items: [],
          label: "",
        },
        {
          title: "FAQ",
          href: `/docs/sections/faq`,
          items: [],
          label: "",
        },
        {
          title: "Footer",
          href: `/docs/sections/footer`,
          items: [],
          label: "",
        },
      ],
    },
  ],
};
